// @import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:wght@300;400&display=swap');
@use 'vars' as *;


@font-face {
    font-family: "Larken-Black";
    src: url('../assets/larken.woff2') format('woff2'), url('../assets/larken.woff') format('woff');
    font-display: swap;
}  
  
@font-face {
    font-family: SebastianBobby;
    src: url("../assets/SebastianBobby.otf") format("opentype");
    font-display: swap;
}
@font-face {
    font-family: ITCConduitStd;
    font-weight:400;
    src: url("../assets/ITCConduitITCStdRegular.otf") format("opentype");
    font-display: swap;
}
@font-face {
    font-family: ITCConduitStd;
    font-weight: 300;
    src: url("../assets/ITCConduitITCStdLight.otf") format("opentype");
    font-display: swap;
}
@font-face {
    font-family: ITCConduitStd;
    font-weight: 700;
    src: url("../assets/ITCConduitITCStdBold.otf") format("opentype");
    font-display: swap;
}
@font-face {
    font-family: ITCConduitStd;
    font-weight: 200;
    src: url("../assets/ITCConduitITCStdExtraLight.otf") format("opentype");
    font-display: swap;
}


.font-light{
    font-weight: 100;
}
.font-white{
    color:#fff;
}
.font-sparse{
    letter-spacing: 2px;
}
.page-title{
    vertical-align: middle;
    font-family: $tertiary-font;
}
.cursive{
    font-family: $secondary-font;
}
.title-cursive{
    font-size: 3em;
    vertical-align: middle;
    font-family: $secondary-font;
}
body {
    font-family: $primary-font;
    font-size: 18px;
    color: #0C1116; /* update this to site body colour */
}
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
    max-width: 100%;
}
img {
    height:auto !important;
}

.clear-float {
    clear:both;
}

.button {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    padding: 10px 20px;
    max-width: 100%;
    color: #0C1116;
    background-color: transparent;
    border: 1px solid #0C1116;
    box-sizing: border-box;
    transition: all 0.3s ease;
}
.button:hover {
    color: grey;
    background-color: transparent;
}

img.display-left {
    float:left;
    max-width: 50%;
    margin-right:20px;
    margin-bottom:10px;
}

img.display-right {
    float:right;
    max-width: 50%;
    margin-left:20px;
    margin-bottom:10px;
}

@media only screen and (max-width: 760px){
    img.display-left,
    img.display-right {
        float: none;
        max-width: 100%;
        margin: 20px 0;
    }
}


$colors: (
    'black': #0C1116,
    'dark-blue': #242F3A,
    'green-cyan': #A9B4B0,
    'dark-green': #003C2B,
    'brown': #9A6C1A,
    'walnut': #61301C
);

@each $color, $value in $colors {
    .#{$color}-bg {
        background: $value;
    }
    .#{$color}-bg-hover:hover {
        background: $value!important;
    }
    .#{$color}-bg-light{
        background: lighten($value, 10%);
    }
    .#{$color}{
        color: $value;
    }
}

